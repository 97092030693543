<template>
  <div>
    <div class="card">
      ThemedicUpdate {{perf}}
      <DataTable class="p-datatable-sm"
        ref="dt" :value="themedics" dataKey="id" 
        :paginator="true" :rows="10" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        responsiveLayout="scroll"
        stripedRows selectionMode="single">
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="キーワード" />
          </span>
        </template>
        <Column field="id" header="ID" :sortable="true" style="min-width:12rem"></Column>
        <Column field="theme_ext" header="外部テーマ名ja" :sortable="true" style="min-width:16rem"></Column>
        <Column field="theme" header="テーマ名ja" :sortable="true" style="min-width:16rem"></Column>
        <Column field="theme_en" header="テーマ名en" :sortable="true" style="min-width:16rem"></Column>
        <Column field="theme_ko" header="テーマ名ko" :sortable="true" style="min-width:16rem"></Column>
        <Column field="idate" header="登録日" :sortable="true" style="min-width:16rem"></Column>
        <Column :exportable="false" style="min-width:8rem">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editThemedic(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="themedicDialog" :style="{width: '450px'}" header="テーマ辞書" :modal="true" class="p-fluid">
      <div class="field">
        <label for="theme_ext">{{themedic.theme_ext}} ({{themedic.idate}})</label>
      </div>
      <div class="field">
        <label for="theme">テーマ名ja</label>
        <InputText id="theme" v-model.trim="themedic.theme" required="true" />
      </div>
      <div class="field">
        <label for="theme_en">テーマ名en</label>
        <InputText id="theme_en" v-model.trim="themedic.theme_en" required="true" />
      </div>      
      <div class="field">
        <label for="theme_ko">テーマ名ko</label>
        <InputText id="theme_ko" v-model.trim="themedic.theme_ko" required="true" />
      </div>
      <div class="field">
        <label for="passcode">パスコード</label>
        <InputText id="passcode" v-model.trim="themedic.passcode" required="true" />
      </div>      
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveThemedic" />
      </template>
    </Dialog>

  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import ThemedicUpdateService from '@/service/ThemedicUpdateService';
import axios from 'axios';

export default {
  data() {
    return {
      perf: null,
      themedics: null,
      themedicDialog: false,
      themedic: {},
      filters: {},
      submitted: false
    }
  },
  themedicUpdateService: null,
  created() {
    this.themedicUpdateService = new ThemedicUpdateService();
    this.initFilters();
  },
  mounted() {
    this.themedicUpdateService.getAll()
    .then(d => {
      this.themedics = d.data;
      this.perf = d.perf;
    });
  },
  methods: {
    hideDialog() {
      this.themedicDialog = false;
      this.submitted = false;
    },
    saveThemedic() {
      this.submitted = true;

      let param_themedic = {
        theme_ext: this.themedic.theme_ext,
        theme: this.themedic.theme,
        theme_en: this.themedic.theme_en,
        theme_ko: this.themedic.theme_ko,
        idate: this.themedic.idate,
        passcode: this.themedic.passcode
      };

      axios.patch('https://api.tradersr.com/api/themedicupdate/' + this.themedic.id, param_themedic)
      .then(res => {
        if(res.data.data){
          this.themedic = res.data.data;
          this.themedics[this.findIndexById(this.themedic.id)] = this.themedic;
        }
      })
      .catch(e => {
        console.log(e);
      })

      this.themedicDialog = false;
      this.themedic = {};
    },
    editThemedic(themedic) {
      this.themedic = {...themedic};
      this.themedicDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.themedics.length; i++) {
        if (this.themedics[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>